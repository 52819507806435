const _headers = (options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    options.headers.set("Content-Type", "application/json");
    const token = localStorage.getItem("token");
    if (token) {
        options.headers.set("Authorization", `Bearer ${token}`);
        options.headers.set("requested-from", "portal");
    }
    return options;
};


export const post = async (url, payload) => {
    const { headers } = await _headers();
    return await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
    });
};

export const patch = async (url, payload) => {
    const { headers } = await _headers();
    return await fetch(url, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(payload),
    });
};

export const put = async (url, payload) => {
    const { headers } = await _headers();
    return await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(payload),
    });
};

export const get = async (url) => {
    const { headers } = await _headers();
    return await fetch(url, {
        method: 'GET',
        headers: headers,
    });
};


export const upload = async (url, formData) => {
    const token = localStorage.getItem('token');
    return await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
