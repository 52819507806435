export const notifications_field = [
    "notify_delivery_on_cancelled_email",
    "notify_collection_on_cancelled_email",
    "notify_delivery_on_delivery_exception_email",
    "notify_collection_on_delivery_exception_email",
    "notify_collection_on_collection_exception_email",
    "notify_delivery_on_delivered_email",
    "notify_collection_on_delivered_email",
    "notify_delivery_on_delivery_failed_attempt_email",
    "notify_collection_on_delivery_failed_attempt_email",
    "notify_collection_on_collection_failed_attempt_email",
    "notify_collection_on_submitted_email",
    "notify_collection_on_collected_email",
    "notify_delivery_on_collected_email",
    "notify_collection_on_at_destination_hub_email",
    "notify_delivery_on_at_destination_hub_email",
    "notify_collection_on_out_for_delivery_email",
    "notify_delivery_on_out_for_delivery_email",
    "notify_collection_on_ready_for_pickup_email",
    "notify_delivery_on_ready_for_pickup_email",
    "notify_delivery_on_shipment_address_changed_email",
    "notify_collection_on_shipment_address_changed_email",
    "notify_collection_in_locker",
    "notify_delivery_in_locker",
    "hide_collection_address_on_waybills_and_emails_to_the_delivery_contact",
    "hide_account_name_on_sms_and_email_notifications",
    "disable_billing_emails",
    "send_email_after_failed_collection_attempt",
    "send_email_after_failed_collection_attempt_after",
    "send_email_after_failed_delivery_attempt",
    "send_email_after_failed_delivery_attempt_after",
    "send_email_after_shipment_address_changed",
];

export const WhatsAppNotifications_field = [
    "notify_collection_on_submitted_whatsapp",
    "notify_collection_on_collected_whatsapp",
    "notify_delivery_on_collected_whatsapp",
    "notify_collection_on_at_destination_hub_whatsapp",
    "notify_delivery_on_at_destination_hub_whatsapp",
    "notify_collection_on_out_for_delivery_whatsapp",
    "notify_delivery_on_out_for_delivery_whatsapp",
    "notify_collection_on_ready_for_pickup_whatsapp",
    "notify_delivery_on_ready_for_pickup_whatsapp",
    "notify_collection_on_collection_failed_attempt_whatsapp",
    "notify_collection_on_delivery_failed_attempt_whatsapp",
    "notify_delivery_on_delivery_failed_attempt_whatsapp",
    "notify_collection_on_delivered_whatsapp",
    "notify_delivery_on_delivered_whatsapp",
    "notify_collection_on_collection_exception_whatsapp",
    "notify_collection_on_delivery_exception_whatsapp",
    "notify_delivery_on_delivery_exception_whatsapp",
    "notify_collection_on_cancelled_whatsapp",
    "notify_delivery_on_cancelled_whatsapp",
    "notify_collection_on_shipment_address_changed_whatsapp",
    "notify_delivery_on_shipment_address_changed_whatsapp",
    "notify_collection_in_locker_whatsapp",
    "notify_delivery_in_locker_whatsapp"
];