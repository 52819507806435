import React, { createContext, useContext } from 'react';
import GeneralDataLogic from 'core/logic/GeneralDataLogic';
import { useDispatch } from 'react-redux';
import { getSettings, get_phone_codes } from "infra/services/api";
import { setGeneralData, setSettings } from 'core/redux/actions/generalDataActions';
import NewOrderLogic from "core/logic/NewOrderLogic";
import { arrays,define_settings } from './SettingsContext';

const GeneralDataContext = createContext({});

export const GeneralDataProvider = ({ children }) => {
    const _pudoLockers = NewOrderLogic();
    const dispatch = useDispatch();
    const _general_data = GeneralDataLogic();
    const account_id = localStorage.getItem('account_id');
    const [is_fetching_general_data, set_is_fetching_general_data] = React.useState(false)
    const [all_shipment_status, set_all_shipment_status] = React.useState([])
    const [all_shipments_date_type, set_all_shipments_date_type] = React.useState([])
    const [all_shipments_period, set_all_shipments_period] = React.useState([])
    const [lockers, set_lockers] = React.useState([])
    const [selected_date_period_filter, set_selected_date_period_filter] = React.useState(null)

    const formatData = async (settings_data) => {
        const { provider_settings } = settings_data;
        if (Array.isArray(provider_settings)) {
            const json_data = {};

            provider_settings.forEach((element) => {
                let value = '';
                if (arrays.indexOf(element.setting) !== -1) {
                    value = JSON.parse(element.value);
                } else {
                    value = element.value;
                }

                json_data[element.setting] = value;
            });

            const response = await get_phone_codes();
            const filteredData = response.map((val) => val.code);

            dispatch(setSettings({...define_settings, ...json_data, phone_codes: filteredData }));
        }
    };

    React.useEffect(() => {
        if (account_id) {
            const getGeneralData = async () => {
                set_is_fetching_general_data(true)
                try {
                    // refetch the provider settings
                    const response_settings = await getSettings(account_id);
                    if (response_settings) {
                        formatData(response_settings);
                    }

                    const response = await _general_data.get_general_data();
                    if (!response) return;
                    const statuses = response?.filters?.shipments_status || [];
                    const shipments_date_type = response?.filters?.shipments_date_type || [];
                    const shipments_period = response?.filters?.shipments_period || [];
                    set_all_shipment_status(Object.entries(statuses).map((val) => ({
                        id: val[0],
                        name: val[1],
                    })))
                    set_all_shipments_date_type(Object.entries(shipments_date_type).map((val) => ({
                        id: val[0],
                        name: val[1],
                    })))
                    set_all_shipments_period(Object.entries(shipments_period).map((val) => ({
                        id: val[0],
                        name: val[1],
                    })))

                    dispatch(setGeneralData(response));
                } catch (error) {
                    throw error
                } finally {
                    set_is_fetching_general_data(false);
                }
            };
            getGeneralData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account_id]);


    React.useEffect(() => {
        if (account_id) {
            const fetch_getPudoLockers = async () => {
                try {
                    const response = await _pudoLockers.getPudoLockers();
                    if (!response) return;
                    set_lockers(response);
                } catch (error) {
                    throw error
                }
            }
            fetch_getPudoLockers();
        }
    }, [account_id])


    return (
        <GeneralDataContext.Provider
            value={{
                is_fetching_general_data,
                all_shipment_status,
                all_shipments_date_type,
                all_shipments_period,
                set_selected_date_period_filter,
                selected_date_period_filter,
                lockers: lockers
            }}
        >
            {children}
        </GeneralDataContext.Provider>
    );
};

export default GeneralDataContext;

export const useGeneraldata = () => useContext(GeneralDataContext);
